import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useModeleEconomiques() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const modeleEconomique = ref([])
  const loader = ref(false)
  const modeleEconomiqueProcess = ref(false)
  const modeleEconomiqueSuccess = ref(false)

  const modeleEconomiques = ref([])
  const errors = ref('')

  const getAllModeleEconomiques = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-modele-economiques')
      if (response.data.success === true) {
        loader.value = false
        modeleEconomiques.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getAllTypeContrats = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-type-contrats')
      if (response.data.success === true) {
        loader.value = false
        modeleEconomiques.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getModeleEconomiqueById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-modele-economique-by-id/${id}`)
      if (response.data.success === true) {
        modeleEconomique.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createModeleEconomique = async data => {
    try {
      errors.value = ''
      modeleEconomiqueProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-modele-economique', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        modeleEconomiqueSuccess.value = true
        modeleEconomiqueProcess.value = false
        modeleEconomique.value = response.data.data
        await getAllModeleEconomiques()
      }
    } catch (error) {
      modeleEconomiqueProcess.value = false
      modeleEconomiqueSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateModeleEconomique = async data => {
    try {
      errors.value = ''
      modeleEconomiqueProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-modele-economique/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        modeleEconomiqueSuccess.value = true
        modeleEconomiqueProcess.value = false
        modeleEconomique.value = response.data.data
        await getAllModeleEconomiques()
      }
    } catch (error) {
      modeleEconomiqueProcess.value = false
      modeleEconomiqueSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    modeleEconomique,
    modeleEconomiques,
    modeleEconomiqueProcess,
    loader,
    modeleEconomiqueSuccess,
    getAllModeleEconomiques,
    getModeleEconomiqueById,
    createModeleEconomique,
    updateModeleEconomique,
    getAllTypeContrats,

  }
}
